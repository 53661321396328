import React from 'react';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Checkbox, CheckboxProps } from '@mui/material';
import styled from 'styled-components';
import { Controller, useFormContext } from 'react-hook-form';

import { FieldWrapper } from '../FieldWrapper';
import { Colors } from '../../../../styles/colors';

export const CheckboxInput = ({
  name,
  noMargin,
  label,
  isChecked,
  onChange: parentOnChange,
  ...rest
}: CheckboxInputProps) => {
  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      defaultValue={!!isChecked}
      render={({ field: { value, onChange, ...restField }, fieldState }) => (
        <FieldWrapper noMargin={noMargin}>
          {/* <pre>{JSON.stringify({ value, restField, fieldState }, null, 2)}</pre> */}
          <FormGroup>
            <Wrapper>
              <FormControlLabel
                control={
                  <Checkbox
                    sx={{
                      color: Colors.Primary,
                      display: 'flex',
                      flex: '0 0 48px',
                      padding: '0',
                      margin: '0',
                      svg: {
                        width: '2rem',
                        height: '2rem',
                      },
                      '&.Mui-checked': {
                        color: Colors.Contrast,
                      },
                    }}
                    size="medium"
                    checked={value}
                    {...restField}
                    {...rest}
                    onChange={(event) => {
                      onChange(event);
                      if (parentOnChange) {
                        parentOnChange(event);
                      }
                    }}
                  />
                }
                label={label}
              />
            </Wrapper>
          </FormGroup>
        </FieldWrapper>
      )}
    />
  );
};

const Wrapper = styled.div`
  label {
    width: 100%;
  }
`;

type CheckboxInputProps = {
  name: string;
  label: string | React.ReactNode;
  isChecked: boolean | undefined | null;
  noMargin?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
} & CheckboxProps;
